import React from 'react'
import { LiveChatLoaderProvider } from 'react-live-chat-loader'
import Layout from './layout'

export default function LiveChatLayout({ children }) {
  return (
    <LiveChatLoaderProvider providerKey="g2hz3vz6xy9p" provider="drift">
      <Layout>{children}</Layout>
    </LiveChatLoaderProvider>
  )
}
